import { createContext } from "react";

const AppContext = createContext({
  token: null,
  setToken: () => { },
  removeToken: () => { },
  session: null,
  setSession: () => { },
  removeSession: () => { },
  loading: false,
  message: "",
  showLoading: (message) => { },
  hideLoading: () => { },
  showNotifyMessage: (messageNotify) => { },
  tituloTela: "",
  setTituloTela: (tituloTela) => { },
  removeTituloTela: () => { },
  showMessage: (tipo, mensagem) => { },
  acessos: [],
  setAcessos: (acessos) => { },
  removeAcessos: () => { },
  modulo: [],
  setModulo: (modulo) => { },
  removeModulo: () => { },
  showSideBar: false,
  setShowSideBar: (showSideBar) => { },
  removeShowSideBar: () => { },
  limparSessaoAnterior: () => { },
  obterClienteInfo: () => { },
});

export default AppContext;
