import React, { useContext } from "react";
import "./Modulos.css";
import AppContext from "../../Store/AppContext";
import { ListaAcessos } from "../../Services/Acessos";
const images = require.context("../../assets/img/icones/modulos", true);

const Modulo = ({ idModulo, titulo, descricao, icone }) => {
  const { setAcessos, setModulo, setShowSideBar } = useContext(AppContext);
  const imagemIcone = images(`./${icone}`);
  const handleOnClick = async (modulo, titulo) => {
    const responseListaAcessos = await ListaAcessos(idModulo);
    setAcessos(await responseListaAcessos.funcoes);
    setModulo({ idModulo, titulo });
    setShowSideBar(true);
    window.location.replace(`/Home`);
  };
  return (
    <div className="telaModulosV2Card"
      role="button"
      onClick={() => handleOnClick(idModulo, titulo)}>
      <img src={imagemIcone} alt={titulo} />
      {descricao}
    </div>
  );
};

export default Modulo;
