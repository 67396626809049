import React, { useState, useEffect, useContext, Fragment } from "react";
import "./Index.css";
import Modulo from "../../Components/Modulo/Modulo";
import { ListaModulos } from "../../Services/Acessos";
import AppContext from "../../Store/AppContext";

function initialState() {
  return {
    modulos: [],
  };
}

export const PagesModulosIndex = () => {
  const { showLoading, hideLoading, obterClienteInfo } = useContext(
    AppContext
  );
  const [values, setValues] = useState(initialState);

  async function loadDependences() {
    showLoading("Carregando Modulos do Sistema");
    const responseListaModulos = await ListaModulos({});

    if (!responseListaModulos.success) {
      // showMessage("Erro", "Erro Inesperado ao carregar dados basicos.");
      return { produtos: [] };
    }

    const modulos = await responseListaModulos.modulos;
    hideLoading();
    return { modulos };
  }

  useEffect(() => {
    loadDependences().then(function (data) {
      setValues({
        ...values,
        modulos: data.modulos,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div class="telaModulosV2Body">
      {obterClienteInfo().clienteLogoEscuro ? <div class="telaModulosV2Logo">
        <img src={obterClienteInfo().clienteLogoEscuro} alt="" height="60" className="telaModulosV2LogoImg" />
      </div> : null
      }

      <div className={obterClienteInfo().clienteImagemSelecaoModulo ? "telaModulosV2ContainerModules" : "telaModulosV2ContainerModulesSemImagem"}>
        <div className={obterClienteInfo().clienteImagemSelecaoModulo ? "telaModulosV2Modules" : "telaModulosV2ModulesSemImagem"} >
          {values.modulos
            ? values.modulos.map((modulo) => (
              <Modulo
                key={modulo.id}
                idModulo={modulo.id}
                icone={modulo.icone}
                titulo={modulo.titulo}
                descricao={modulo.descricao}
              />
            ))
            : null}

        </div>
        <div className="telaModulosVHeroImg">
          <img src={obterClienteInfo().clienteImagemSelecaoModulo} alt="" width="600" />
        </div>
      </div>
    </div>

  );
};

export default PagesModulosIndex;
