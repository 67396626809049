import React, { useState } from "react";
import AppContext from "./AppContext";
import useStorage from "../Utils/useStorage";
import { Alerta } from "../Utils/Alerta";

const StoreProvider = ({ children }) => {
  const [token, setToken, removeToken] = useStorage("token");
  const [session, setSession, removeSession] = useStorage("session");
  const [tituloTela, setTituloTela, removeTituloTela] = useStorage(
    "tituloTela"
  );
  const [acessos, setAcessos, removeAcessos] = useStorage("acessos");
  const [modulo, setModulo, removeModulo] = useStorage("modulo");
  const [showSideBar, setShowSideBar, removeShowSideBar] = useStorage("showSideBar");

  function initialState() {
    return {
      loading: false,
      message: "",
      showNotify: false,
      messageNotify: "",
      modulos: [],
      tituloTela: "",
    };
  }

  const [values, setValues] = useState(initialState());

  const showLoading = (message = "Aguarde") =>
    setValues({
      ...values,
      loading: true,
      message: message,
    });

  const hideLoading = () =>
    setValues({
      ...values,
      loading: false,
    });

  const showMessage = (tipo, mensagem) => {
    Alerta(tipo, mensagem);
  };

  const limparSessaoAnterior = () => {
    removeToken();
    removeSession();
    removeTituloTela();
    removeAcessos();
    removeModulo();
  };

  const obterClienteInfo = () => {
    const clienteConfigValue = process.env.REACT_APP_CLIENTE_CONFIG;

    if (!clienteConfigValue) {
      return {
        clienteConfig: null,
        clienteLogoClaro: null,
        clienteLogoEscuro: null,
        clienteImagemSelecaoModulo: null
      };
    }


    return {
      clienteConfig: clienteConfigValue,
      clienteLogoClaro: `/Clientes/${clienteConfigValue}/logoClaro.png`,
      clienteLogoEscuro: `/Clientes/${clienteConfigValue}/logoEscuro.png`,
      clienteImagemSelecaoModulo: `/Clientes/${clienteConfigValue}/imagemSelecaoModulo.png`
    };
  };


  return (
    <AppContext.Provider
      value={{
        token: token,
        setToken: setToken,
        removeToken: removeToken,
        session: session,
        setSession: setSession,
        removeSession: removeSession,
        loading: values.loading,
        message: values.message,
        showLoading: showLoading,
        hideLoading: hideLoading,
        tituloTela: tituloTela,
        setTituloTela: setTituloTela,
        removeTituloTela: removeTituloTela,
        showMessage: showMessage,
        acessos: acessos,
        setAcessos: setAcessos,
        removeAcessos: removeAcessos,
        modulo: modulo,
        setModulo: setModulo,
        removeModulo: removeModulo,
        showSideBar: showSideBar,
        setShowSideBar: setShowSideBar,
        removeShowSideBar: removeShowSideBar,
        limparSessaoAnterior: limparSessaoAnterior,
        obterClienteInfo: obterClienteInfo
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default StoreProvider;
