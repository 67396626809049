import React, { Fragment, useContext, useState, useEffect } from "react";
import "./Navbar.css";
import { useHistory } from "react-router-dom";
import AppContext from "../../Store/AppContext";
import Avatar from "../../Components/Avatar/Avatar";
import RedefinicaoSenha, {
  showRedefinicaoSenha,
} from "../../Components/RedefinicaoSenha/RedefinicaoSenha";
import { FavoritarPath } from "../../Services/Acessos";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faHome } from '@fortawesome/free-solid-svg-icons';
import { ListaAcessos } from "../../Services/Acessos";

function initialState() {
  return {
    showMenu: true,
  };
}



const Navbar = () => {

  useEffect(() => {
    async function carregarDependencias() {
      const responseListaAcessos = await ListaAcessos(modulo.idModulo);
      setAcessos(await responseListaAcessos.funcoes);
    }
    carregarDependencias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const { tituloTela, acessos, session, modulo, showSideBar, setAcessos, setShowSideBar, showLoading, hideLoading } = useContext(AppContext);

  const ehFavorito = () => {
    function buscaPath(items, path) {
      for (let item of items) {
        if (item.path.toLowerCase() === path) {

          return item.favoritado ? 'yes' : 'no';
        }

        if (item.subMenus && item.subMenus.length > 0) {
          let result = buscaPath(item.subMenus, path);
          if (result) return result;
        }
      }
      return null;
    }

    let urlPath = window.location.pathname.toLowerCase().substring(1);
    urlPath = urlPath.toLowerCase();
    const result = buscaPath(acessos, urlPath);
    return result ? result : 'none';
  }

  const [ehFavoritoValues, setEhFavoritoValues] = useState(ehFavorito);
  const sair = () => {
    localStorage.clear();
    return history.push("/");
  };

  const telaInicial = () => {
    return history.push("/Modulos");
  };

  function showMenu() {
    setShowSideBar(!showSideBar);
  }


  const favoritarPathHandler = async () => {
    showLoading();
    let urlPath = window.location.pathname.toLowerCase().substring(1);
    urlPath = urlPath.toLowerCase();
    FavoritarPath(urlPath);
    setEhFavoritoValues(ehFavoritoValues === "yes" ? "no" : "yes");
    const responseListaAcessos = await ListaAcessos(modulo.idModulo);
    setAcessos(await responseListaAcessos.funcoes);
    hideLoading();
  };


  const acessarHome = async () => {
    return history.push("/Home");
  };


  return (
    <Fragment>
      <RedefinicaoSenha id="redefinirSenharNavBar" />
      <nav className="navbar navbar-expand-lg navbar-light bg-transparent border-bottom mb-3">
        {!showSideBar ?
          <div
            role="button"
            className={`title-page-home`}
            onClick={() => acessarHome()}
          >
            <FontAwesomeIcon style={{ fontSize: 20 }} icon={faHome} />
          </div> : null
        }
        <div id="menu-toggle" className="itens_navbar">
          <svg
            className={
              showSideBar
                ? "ham hamRotate ham1 active"
                : "ham hamRotate ham1"
            }
            viewBox="0 0 100 100"
            width="80"
            onClick={showMenu}
          >
            <path
              className="line top"
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            />
            <path className="line middle" d="m 30,50 h 40" />
            <path
              className="line bottom"
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            />
          </svg>
          <div className="title-page" >
            {modulo.titulo + " / " + tituloTela}
            <div
              role="button"
              className={`title-page-fav fav-${ehFavoritoValues}`}
              onClick={() => favoritarPathHandler()}
            >
              <FontAwesomeIcon style={{ fontSize: 20 }} icon={faStar} />
            </div>
          </div>

        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0 align-items-center">
            {/* <li className="nav-item dropdown">
            <div
              className="nav-link dropdown mr-3"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="navBarIconeNotificacoes">
                <FontAwesomeIcon icon={faBell} />
              </i>
              <span className="notification-badge">1</span>
            </div>

            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <div className="dropdown-item" role="button" href="#">
                1
              </div>
              <div className="dropdown-item" role="button" href="#">
                2
              </div>
              <div className="dropdown-divider"></div>
              <div className="dropdown-item" role="button" href="#">
                3
              </div>
            </div>
          </li> */}

            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle navBarUsuarioContainer"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Avatar src={session.usuarioFoto} className="navBar" />
                <div className="navBarUsuarioNome">{` ${session.usuarioNome}`}</div>
              </div>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown"
              >
                <div
                  className="dropdown-item"
                  role="button"
                  onClick={() => {
                    showRedefinicaoSenha("redefinirSenharNavBar", session.usuarioId);
                  }}
                  href="#"
                >
                  Alterar Senha
                </div>

                <div
                  className="dropdown-item"
                  role="button"
                  onClick={telaInicial}
                >
                  Tela Inicial
                </div>
                <div className="dropdown-divider"></div>
                <div className="dropdown-item" role="button" onClick={sair}>
                  Sair
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment >
  );
};

export default Navbar;
