/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext } from 'react';
import AppContext from '../../../../Store/AppContext';
import { ListarRelatorios } from '../../../../Services/Relatorio';
import { validarPermissoesPagina } from '../../../../Utils/validarPermissoesPagina';
export const RelatoriosModuloContext = createContext();

const tituloTela = 'Relatórios';

function initialState() {
	return {
		relatorios: []
	};
}

const RelatoriosModuloProvider = ({ children }) => {
	const { showLoading, hideLoading, setTituloTela, modulo } = useContext(AppContext);
	const [values, setValues] = useState(initialState());

	async function loadDependences() {

		const [
			permissoesPaginaAux,
			relatorios
		] = await Promise.all([
			validarPermissoesPagina(),
			ListarRelatorios()
		]);

		var relatoriosAtivos = relatorios.relatorios.find(x => x.relatorioModulo === modulo.titulo)

		return {
			permissoesPagina: permissoesPaginaAux,
			relatorios: relatoriosAtivos ? relatoriosAtivos.relatorios : []
		};
	}

	const resetScreen = () => {
		showLoading();
		async function carregarDependencias() {
			const dependencias = await loadDependences();
			setTituloTela(tituloTela);

			setValues({
				...initialState(),
				permissoesPagina: await dependencias.permissoesPagina,
				relatorios: await dependencias.relatorios,
			});

			hideLoading();
		}
		carregarDependencias();
	};


	return (
		<RelatoriosModuloContext.Provider
			value={{
				values,
				setValues,
				resetScreen
			}}
		>
			{children}
		</RelatoriosModuloContext.Provider>
	);
};

export default RelatoriosModuloProvider;
