import React, { Fragment, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../Store/AppContext";
// import AlterarDados from "../../Components/Lead/AlterarDados/AlterarDados";
import "./Index.css";
const images = require.context("../../assets/img/icones/sidebar", true);

const tituloTela = "Home";

const Index = () => {
  const { acessos, setTituloTela } = useContext(AppContext);

  useEffect(() => {
    async function carregarDependencias() {
      setTituloTela(tituloTela);
    }
    carregarDependencias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFavoriteItems = (items) => {
    let favorites = [];

    items.forEach(item => {
      // Check if the current item is favorited
      if (item.favoritado) {
        favorites.push(item);
      }

      // Recursively check submenus if they exist
      if (item.subMenus && item.subMenus.length > 0) {
        favorites = favorites.concat(getFavoriteItems(item.subMenus));
      }
    });

    return favorites;
  };

  const MenuItem = ({ item }) => {
    return (
      <NavLink to={item.path} className="text-decoration-none">
        <div className="card favoritoContainerCard">
          <div  >
            <img
              className="favoritoContainerIcone"
              src={images(`./${item.icone ? item.icone : "dashboard.png"}`)}
              alt=""
            />
            <p className="favoritoContainerTitulo">{item.nome}</p>
          </div>
        </div>
      </NavLink>
    );
  };

  return <Fragment>
    <div className="row mt-4 ml-3 contentFavoritos">
      {getFavoriteItems(acessos).map(item => (
        <div key={item.id} className="col-md-2 mt-3">
          <MenuItem key={item.id} item={item} />
        </div>
      ))}
    </div>
  </Fragment>;
};

export default Index;
